import { Form, useSearchParams } from '@remix-run/react';
import { authenticator } from '~/services/auth.server';
import type { LinksFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import { FcGoogle } from 'react-icons/fc'
import styles from '~/styles/pages/login.css?url';
import { Button } from '~/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '~/components/ui/card';
import { SimplePageLayout } from '~/components/layouts/SimplePageLayout';
import { Alert, AlertDescription } from '~/components/ui/alert';

export const meta: MetaFunction = () => {
  return [
    { title: 'Login - Krovy dashboard' },
    { name: 'description', content: 'Login in krovy dashboard!' },
  ];
};

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles }
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return await authenticator.isAuthenticated(request, {
    successRedirect: '/'
  });
}

export default function Login() {
  const [searchParams] = useSearchParams();
  const hasLoginError = searchParams.get('error');
  return <SimplePageLayout>
    <div className="flex items-center justify-center w-full">
      <Card>
        <CardHeader>
          <CardTitle>Welcome to Krovy</CardTitle>
          <CardDescription>Log in to access your dashboard</CardDescription>
        </CardHeader>
        <CardContent>
          <Form action="/auth/google" method="post" className="w-full">
            <Button type="submit" variant="outline" className="w-full">
              <FcGoogle className="mr-2 h-4 w-4"/>
              Sign in with Google
            </Button>
          </Form>
          {hasLoginError && <Alert variant="destructive" className="mt-4">
            <AlertDescription>There was an error trying to log in</AlertDescription>
          </Alert>}
        </CardContent>
      </Card>
    </div>
  </SimplePageLayout>
}
